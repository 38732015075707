.about-container {
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
    color: #444444;
  }
  
  .about-container h1 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .about-container h2 {
    margin-top: 20px;
  }
  
  .about-container p, .about-container ul, .about-container ol {
    line-height: 1.6;
  }
  
  .about-container a {
    color: #444444;
    text-decoration: none;
  }
  
  .about-container a:hover {
    text-decoration: underline;
  }
  
  /* New styles for the back to chat link */
  .back-to-chat {
    display: inline-block;
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #444444;
    color: #F9F6EE !important;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s ease;
  }
  
  .back-to-chat:hover {
    background-color: #555555;
    text-decoration: none;
  }

pre {
    padding: 10px;
    background-color: #444444;
    color: #F9F6EE;
}